import React, { useEffect, useState, useRef } from 'react'
import Api from '../api.js';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default function Tabla({ info, noQueries = false, noFilter = false, preSearch = false, filter = [], openVisor = false, forceRender, style = [] }) {
    let api = new Api();
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let location = useLocation();

    let [tableInfo, setTableInfo] = useState([]);
    let [pages, setPages] = useState(1);
    let [sort, setSort] = useState({ by: "", order: "" });
    let page = useRef(1);
    let searchParameter = useRef("");
    let listStatus = useRef(1);

    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        const timer = setTimeout(() => {
            listar()
        }, 500)

        return () => clearTimeout(timer)
    }, [searchValue])


    useEffect(() => {
        if (infoUsuario.token !== null) {
            listar();
        }
    }, [infoUsuario, sort, forceRender, preSearch]);


    async function listar() {
        console.log("EL presearch es " + preSearch)

        if (preSearch == "noBuscar") {
            return;
        }
        let filter = {};
        filter.statusId = parseInt(listStatus.current.value);
        if (preSearch) {
            filter.documentType = 1;
        }

        let endpoint2;
        if (noFilter) {
            const connector = info.endpoint.includes('?') ? '&' : '?';
            endpoint2 = info.endpoint + connector + 'page=' + page.current.value;
        } else {
            endpoint2 = info.endpoint + '?page=' + page.current.value + '&filter=' + JSON.stringify(filter);
        }


        if (searchParameter.current.value !== "") {
            if (searchParameter.current.value.length < 3) {
                return;
            }
            endpoint2 += '&search=' + searchParameter.current.value;
        }

        if (preSearch != false) {
            endpoint2 += '&filter={"patientId":' + preSearch + '}';
        }

        if (sort.by !== "") {
            endpoint2 += '&order=' + JSON.stringify([[sort.by, sort.order]]);
        }

        if (noQueries) {
            endpoint2 = info.endpoint;
        }
        console.log(endpoint2)
        var resp = await api.get(endpoint2, infoUsuario.token);

        if (!resp.error) {
            if (info.infoInKey) {
                setTableInfo(resp.info[info.infoInKey]);
            } else {
                setTableInfo(resp.info);
            }

            if (resp.pages == 0) {
                setPages(1);
            } else {
                setPages(resp.pages);
            }

        }
    }

    async function filtrar(val) {
        let filter = { statusId: val.value };
    
        let endpoint2;
        const connector = info.endpoint.includes('?') ? '&' : '?';
    
        // Valido si existe un filtro en el endponit
        const existingFilterMatch = info.endpoint.match(/filter=({.*?})/);
        if (existingFilterMatch) {
            // Solo si existe un segundo filtro
            const existingFilter = JSON.parse(decodeURIComponent(existingFilterMatch[1]));
            filter = { ...existingFilter, ...filter }; // Combina los filtros
            endpoint2 = info.endpoint.replace(/filter=({.*?})/, '') + connector + 'page=' + page.current.value + '&filter=' + encodeURIComponent(JSON.stringify(filter));
        } else {
            // Si no hay, se hace como antes
            endpoint2 = info.endpoint + '?page=' + page.current.value + '&filter=' + encodeURIComponent(JSON.stringify(filter));
        }

        console.log('Endpoint después de evaluar filtro en filtrar:', endpoint2);
    
        var resp = await api.get(endpoint2, infoUsuario.token);
    
        if (!resp.error) {
            if (info.infoInKey) {
                setTableInfo(resp.info[info.infoInKey]);
            } else {
                setTableInfo(resp.info);
            }
    
            if (resp.pages == 0) {
                setPages(1);
            } else {
                setPages(resp.pages);
            }
        }
    }

    const createUrl = (text, info) => {
        let result = [];
        let currentText = "";
        let inVariable = false;

        for (let i = 0; i < text.length; i++) {
            let char = text[i];
            if (char === "$") {
                if (inVariable) {
                    result.push({ type: "variable", value: currentText });
                    currentText = "";
                    inVariable = false;
                } else {
                    result.push({ type: "text", value: currentText });
                    currentText = "";
                    inVariable = true;
                }
            } else {
                currentText += char;
            }
        }

        if (currentText) {
            result.push({ type: inVariable ? "variable" : "text", value: currentText });
        }

        result = result.map((section) => {
            if (section.type == "text") {
                return section.value;
            } else {
                return info[section.value];
            }
        }).join("");

        return result;
    }

    async function toggleStatus(event, id, url) {
        var t = window.confirm("Esta seguro que desea cambiar el estado?");

        if (t !== true) {
            return;
        }
        let data;
        data = { statusId: parseInt(event.target.value) }

        var resp = await api.put(url + "/" + id, data, infoUsuario.token);

        if (!resp.error) {
            listar();
        }
    }

    async function sortFunc(sortBy) {
        if (sort.by == sortBy) {
            if (sort.order == "ASC") {
                setSort({ by: sortBy, order: "DESC" })
            } else {
                setSort({ by: sortBy, order: "ASC" })
            }
        } else {
            setSort({ by: sortBy, order: "ASC" })
        }
    }

    let controls = "pageSubHeading";
    if (noQueries) {
        controls = "pageSubHeadingHidden";
    }

    let filterVisible = "none";
    if (filter.length !== 0) {
        filterVisible = "flex";
    }
    let [isSearching, setIsSearching] = useState(false);
    if (preSearch) {
        if (!isSearching) {
            //searchParameter.current.value = preSearch;
            filter = "documentType";
            listar();
            setIsSearching(true);
        }
    }
    return (
        <>
            <div className={controls} style={style}>
                <input type="search" className="searchInput" placeholder="Buscar" ref={searchParameter} onChange={e => setSearchValue(e.target.value)} value={searchValue} />

                <Select styles={{ container: (base) => ({ ...base, width: "40%", display: filterVisible }) }} onChange={(val) => { filtrar(val); }} options={filter} />

                <div><span class="pageSelectorText">Página</span> <select className="pageSelector" ref={page} onChange={listar}>{Array.from(Array(pages), (e, i) => { return <option key={i + 1} value={i + 1}>{i + 1}</option> })}</select> de {pages}</div>
            </div>
            <div className="tableContainer" style={style}>
                <table>
                    <thead>
                        <tr>
                            {info.titulos.map((titulo, index) => {
                                if (titulo.name == "status") {
                                    return <th key={index}><select className="statusSelect" ref={listStatus} onChange={listar}>{infoGeneral.status.map((status, index2) => { return <option key={index2} value={status.id}>{status.name}</option> })}</select></th>;
                                } else {
                                    if (titulo.name) {
                                        return <th key={index} onClick={() => { sortFunc(titulo.name) }}>{titulo.texto}</th>
                                    } else {
                                        return <th key={index}>{titulo.texto}</th>
                                    }

                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {tableInfo.map((row, index) => {
                            return <tr key={index}>
                                {info.columnas.map((col, index) => {
                                    if (col["type"] == "text") {
                                        let v;
                                        if (col["nested"]) {
                                            v = row[col["prop"]][col.nested];
                                        } else {
                                            v = row[col["prop"]];
                                        }

                                        if (openVisor && col["openVisor"]) {
                                            if (col["format"]) {
                                                return <td key={index} onClick={() => { openVisor(row["fileMimetype"], row["file"], row["id"]) }}>{col["format"](v)}</td>
                                            } else {
                                                return <td key={index} onClick={() => { openVisor(row["fileMimetype"], row["file"], row["id"]) }}>{v}</td>
                                            }
                                        } else {
                                            if (col["format"]) {
                                                if (col["prop"] == "") {
                                                    v = row;
                                                }
                                                return <td key={index}>{col["format"](v)}</td>
                                            } else {
                                                return <td key={index}>{v}</td>
                                            }
                                        }

                                    }

                                    if (col["type"] == "boldText") {

                                        let v;
                                        if (col["nested"]) {
                                            v = row[col["prop"]][col.nested];
                                        } else {
                                            v = row[col["prop"]];
                                        }

                                        if (openVisor && col["openVisor"]) {
                                            if (col["format"]) {
                                                return <td style={{ fontWeight: "bold", cursor: "pointer" }} key={index} onClick={() => { openVisor(row["fileMimetype"], row["file"], row["id"], row) }}>{col["format"](v)}</td>
                                            } else {
                                                return <td style={{ fontWeight: "bold", cursor: "pointer" }} key={index} onClick={() => { openVisor(row["fileMimetype"], row["file"], row["id"], row) }}>{v}</td>
                                            }
                                        } else {
                                            if (col["format"]) {
                                                return <td style={{ fontWeight: "bold", cursor: "pointer" }} key={index}>{col["format"](v)}</td>
                                            } else {
                                                return <td style={{ fontWeight: "bold", cursor: "pointer" }} key={index}>{v}</td>
                                            }
                                        }

                                    }
                                    if (col["type"] == "func") {

                                        let v;
                                        if (col["nested"]) {
                                            v = row[col["prop"]][col.nested];
                                        } else {
                                            v = row[col["prop"]];
                                        }

                                        if (col["format"]) {
                                            return <td style={{ fontWeight: "bold", cursor: "pointer" }} key={index} onClick={() => { col["func"](v); }}>{col["placeholder"]}</td>
                                        } else {
                                            return <td style={{ fontWeight: "bold", cursor: "pointer" }} key={index} onClick={() => { col["func"](v); }}>{col["placeholder"]}</td>
                                        }

                                    }

                                    if (col["type"] == "link") {
                                        let v;
                                        if (col["nested"]) {
                                            v = row[col["prop"]][col.nested];
                                        } else if (col.placeholder) {
                                            v = col.placeholder;
                                        } else {
                                            v = row[col["prop"]];
                                        }

                                        if (col["urlProp"]) {
                                            if (col["format"]) {
                                                return <td key={index}><a href={row[col["urlProp"]]} target="_blank">{col["format"](v)}</a></td>;
                                            } else {
                                                if (row[col["urlProp"]].includes(`["`)) {
                                                    return <td key={index}><a href={"#"} target="_blank" onClick={() => {
                                                        var downloadUrls = JSON.parse(row[col["urlProp"]]);
                                                        downloadUrls.forEach(function (value, idx) {
                                                            const response = {
                                                                file: value,
                                                            };
                                                            setTimeout(() => {
                                                                window.location.href = response.file;
                                                            }, idx * 100)
                                                        })
                                                    }}>{v}</a></td>;
                                                }
                                                return <td key={index}><a href={row[col["urlProp"]]} target="_blank">{v}</a></td>;
                                            }
                                        } else if (col["customUrl"]) {
                                            return <td key={index}><a href={col["format"](row)} target="_blank">{row[col["text"]]}</a></td>;
                                        } else {
                                            let url = createUrl(col["url"], row);
                                            if (col["format"]) {
                                                return <td key={index}><Link to={url}>{col["format"](v)}</Link></td>
                                            } else {
                                                return <td key={index}><Link to={url}>{v}</Link></td>
                                            }
                                        }



                                    }

                                    if (col["type"] == "status") {
                                        let limit = 2;
                                        if (col["statusOrder"]) {
                                            limit = 4;
                                        }
                                        const id = col['customId'] ? row[col['customId']] : row["id"];
                                        const valueSelected = col['nested'] ? row[col['nested']]['statusId'] : row["statusId"];
                                        return (
                                            <td key={index}>
                                                <select className="statusSelect" value={valueSelected} onChange={(e) => { toggleStatus(e, id, col["url"]); }}>
                                                    {infoGeneral.status.map((status, index2) => {
                                                        if (status.id <= limit) {
                                                            return <option key={index2} value={status.id}>{status.name}</option>
                                                        }
                                                    })}
                                                </select>
                                            </td>
                                        );
                                    }

                                })}
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

Tabla.propTypes = {
    info: PropTypes.object.isRequired,
    forceRender: PropTypes.number.isRequired
};